// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import KnowledgeArticle from 'components/Contentful/KnowledgeArticle'
import SideNavigation from 'components/SideNavigation'
import PageNavigation from 'components/PageNavigation'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Eine Wärmepumpe ist"
        meta={[
          { name: 'description', content: 'Die Wärmepumpe ist nicht nur kostengünstig im Betrieb, sondern auch intelligent, einfach sowie sicher und sauber. Die Heizungsmacher AG erklärt Ihnen weshalb.' },
          { name: 'keywords', content: 'Wärmepumpe Erklärung Einfach Beratung Heizungsmacher AG' },
        ]}
      >
        
      </Helmet>
      
      <PageNavigation location={props.location} links={[
    {
        "type": "link",
        "id": "3P9prSaMlzdADoC8f1gPT7",
        "updatedAt": "2021-02-11T13:38:26.956Z",
        "label": "Fördergelder",
        "page": {
            "slug": "foerdergelder"
        }
    },
    {
        "type": "link",
        "id": "1xligmPvFk1S9Z6xdQzjAE",
        "updatedAt": "2020-03-27T13:38:18.329Z",
        "label": "Die Wärmepumpe einfach erklärt",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert"
        }
    }
]} /><div className='section__nav section--padded'><SideNavigation location={props.location} links={[
    {
        "type": "link",
        "id": "3YsoMo1NVNMD9R5EUCcPZY",
        "updatedAt": "2020-03-27T12:09:36.532Z",
        "label": "Eine Wärmepumpe ist",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert/eine-waermepumpe-ist"
        }
    },
    {
        "type": "link",
        "id": "4eeVTOLecGuLIeZOm4LSQ",
        "updatedAt": "2020-03-27T14:19:22.162Z",
        "label": "Funktionsweise Wärmepumpe",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert/funktionsweise-einer-waermepumpe"
        }
    },
    {
        "type": "link",
        "id": "mlApPFnIU8bx7k8iiIeJs",
        "updatedAt": "2020-03-27T14:53:37.132Z",
        "label": "Häufig gestellte Fragen - FAQ",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert/haeufig-gestellte-fragen-faq"
        }
    }
]} /><div
          className='bg__flex--white'
          key='4dw60pEJdZy4dp7KMwTZna-1lMqejNefecSqtLyu3qJ7n'
          id=''
        >
          <KnowledgeArticle
            {...{
    "type": "knowledgeArticle",
    "id": "1lMqejNefecSqtLyu3qJ7n",
    "updatedAt": "2020-04-06T06:58:34.120Z",
    "title": "Eine Wärmepumpe ist ...",
    "keyword": "Eine Wärmepumpe ist",
    "articleType": "Infoartikel",
    "description": "Ein alternatives Heizsystem, welches heutzutage sehr gefragt ist. Sie ist nicht nur kostengünstig im Betrieb, sondern auch smart und einfach.",
    "content": [
        {
            "type": "infoarticle",
            "id": "2H8GfhLe9IRQoH1Yiqr9j9",
            "updatedAt": "2020-03-27T10:34:20.077Z",
            "title": "Eine Wärmepumpe ist",
            "text": "<h3>Intelligent</h3>\n<p>Wussten Sie dass eine Wärmepumpe zwei Drittel der benötigten Energie aus der Umwelt gewinnt? Nur ein Drittel ist elektrische Energie. Den benötigten Strom können Sie ausserdem mit einer Solaranlage bereitstellen. Somit haben Sie 100% der Wärme aus der Umwelt.</p>\n<h3>Einfach</h3>\n<p>Einfache und standardisierte Systeme garantieren eine gut funktionierende Wärmepumpe. Durch eine richtige Dimensionierung und Planung vermeiden wir die üblichen Fehler. Genau dies ist unsere Philosophie, je einfacher und standardisierter das System ist, desto effizienter und wartungsarmer ist der Betrieb. Ganz einfach.</p>\n<h3>Sicher und sauber</h3>\n<p>Kein Kaminfeger, keine Rückstände, keine Abgase. Wärmepumpen nutzen Wärme aus der Umwelt, zum Beispiel aus der Umgebungsluft. Zu keiner Zeit ist Brennstoff im Haus und Luft hat es immer genug.</p>\n"
        }
    ],
    "topic": {
        "type": "link",
        "id": "1xligmPvFk1S9Z6xdQzjAE",
        "updatedAt": "2020-03-27T13:38:18.329Z",
        "label": "Die Wärmepumpe einfach erklärt",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert"
        }
    },
    "previewImage": {
        "description": "Wärmepumpe NIBE F2120 aussenaufgestellt",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/9i4Ra5dNE46w2uaA2GmI0/b99ceeea7c545ba23cb18e18be7d67c0/NIBE_F2120_8kW_perspekt_ohne_Schatten.png"
    }
}} location={props.location}
          />
        </div></div>
    </Layout>
  )
}

export default Page
